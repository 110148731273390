import {Component, OnInit} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../services/env.service';
import {stringify} from 'querystring';

@Component({
    selector: 'app-product',
    templateUrl: './product.page.html',
    styleUrls: ['./product.page.scss'],
})
export class ProductPage implements OnInit {

    product;
    compagny;
    selectablesDays = {
        monday: [],
        tuesday: [],
        thursday: [],
        wednesday: [],
        friday: [],
        saturday: [],
        sunday: [],
    };
    number = 1;

    constructor(private navCtrl: NavController, private modalCtrl: ModalController, private storage: Storage, private  httpClient: HttpClient, public env: EnvService) {
    }

    ngOnInit() {
        var days = this.compagny.opening_times;

        if (days.monday) {
            this.split(days.monday, 'monday');
        }
        if (days.tuesday) {
            this.split(days.tuesday, 'tuesday');
        }
        if (days.thursday) {
            this.split(days.thursday, 'thursday');
        }
        if (days.wednesday) {
            this.split(days.wednesday, 'wednesday');
        }
        if (days.friday) {
            this.split(days.friday, 'friday');
        }
        if (days.saturday) {
            this.split(days.saturday, 'saturday');
        }
        if (days.sunday) {
            this.split(days.sunday, 'sunday');
        }
    }

    split(data, day) {
        data.split(';').forEach(obj => {
            var z = [];
            var x = [];
            obj.split('-').forEach(h => {
                let e = h.split('h');
                if (e[1] == '') {
                    e[1] = '00';
                }
                z.push(e);
            });

            for (let i = parseInt(z[0][0]); i < parseInt(z[1][0]); i++) {
                var b = 0;
                if (z[0][1] != '00') {
                    b = Math.ceil(parseInt(z[0][1]) / 10) * 10;
                }

                for (let j = b; j < 60; j = j + 10) {
                    let d;
                    d = j;
                    if (j == 0) {
                        d = '00';
                    }
                    x.push(['' + i, '' + d]);
                }
            }
            this.selectablesDays[day].push(x);
        });
    }

    async closeModal() {
        await this.modalCtrl.dismiss();
    }

    addToCart(product, number) {
        if (isNaN(number) || number <= 0) {
            return;
        }

        let tmpCart = JSON.parse(localStorage.getItem('cart'));
        product.qty = number;
        product.openedTimes = this.selectablesDays;
        product.compagny = this.compagny;
        var finded = false;
        tmpCart.forEach(obj => {
            if (obj.id == product.id) {
                obj.qty = obj.qty + number;
                finded = true;
            }
        });

        if (!finded) {
            tmpCart.push(product);
        }

        localStorage.setItem('cart', JSON.stringify(tmpCart));
        this.closeModal();
    }

}
