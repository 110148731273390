import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {EnvService} from '../../services/env.service';

@Component({
  selector: 'app-opening-time',
  templateUrl: './opening-time.page.html',
  styleUrls: ['./opening-time.page.scss'],
})
export class OpeningTimePage implements OnInit {

  opening;

  constructor(private navCtrl: NavController, private modalCtrl: ModalController, private storage: Storage, private  httpClient: HttpClient, private env: EnvService) {
  }

  ngOnInit() {
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

}
